<template>
  <section>
    <div class="about paddingP">
      <div class="about_module moduleP">
        <div class="about_en">ABOUT US</div>
        <div v-show="$i18n.locale == 'zh'" class="about_title">
          {{ dataInfo.title }}
        </div>
        <div v-show="$i18n.locale == 'en'" class="about_title">
          {{ dataInfo.title_en }}
        </div>
        <div class="about_text">Linyi Baiyi metal products Co., Ltd</div>
        <div class="about_content">
          <div v-show="$i18n.locale == 'zh'" v-html="dataInfo.content"></div>
          <div v-show="$i18n.locale == 'en'" v-html="dataInfo.content_en"></div>
        </div>
      </div>
    </div>

    <div class="map computer">
      <img :src="address.address_image" class="map_image" alt="" />
      <div class="map_title fa">
        <img
          src="../../assets/images/about_vector.png"
          class="map_icon"
          alt=""
        />
        {{ address.address }}
      </div>
    </div>
    <div class="phone">
      <div class="mapP">
        <img :src="address.address_image" class="map_image moduleP" alt="" />
        <div class="mapP_title fa">
          <img
            src="../../assets/images/about_vector.png"
            class="map_icon"
            alt=""
          />
          {{ address.address }}
        </div>
      </div>
    </div>

    <div class="contact pageP">
      <div class="contact_title">{{ $t("language.contactTitle") }}</div>
      <div class="contact_module computer">
        <div class="fx wp">
          <div class="contact_item fa">
            <span class="contact_important">*</span>{{ $t("language.name") }}:
            <input v-model="form.name" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            <span class="contact_important">*</span>{{ $t("language.phone") }}:
            <input v-model="form.phone" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            {{ $t("language.email") }}:
            <input v-model="form.email" type="text" class="contact_input f1" />
          </div>
          <div class="contact_item fa">
            {{ $t("language.address") }}:
            <input
              v-model="form.address"
              type="text"
              class="contact_input f1"
            />
          </div>
        </div>
        <div class="contact_content fx">
          {{ $t("language.words") }}：
          <textarea
            v-model="form.content"
            class="contact_area f1"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div @click="feedback" class="contact_submit faj">
          {{ $t("language.submit") }}
        </div>
      </div>
      <div class="contactP phone">
        <div class="contact_item contactP_item fa">
          <span class="contact_important">*</span>{{ $t("language.name") }}:
          <input v-model="form.name" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          <span class="contact_important">*</span>{{ $t("language.phone") }}:
          <input v-model="form.phone" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          {{ $t("language.email") }}:
          <input v-model="form.email" type="text" class="contact_input f1" />
        </div>
        <div class="contact_item contactP_item fa">
          {{ $t("language.address") }}:
          <input v-model="form.address" type="text" class="contact_input f1" />
        </div>
        <div class="contact_content contactP_item fx">
          {{ $t("language.words") }}：
          <textarea
            v-model="form.content"
            class="contact_area f1"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div @click="feedback" class="contact_submit faj">
          {{ $t("language.submit") }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qs from "qs";
import { feedback, about } from "@/assets/api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        address: "",
        content: "",
      },
      dataInfo: "",
      address: {
        address: "",
        address_image: "",
      },
    };
  },
  methods: {
    feedback() {
      if (this.form.name == "") {
        this.$message.error("请输入姓名");
      } else if (this.form.phone == "") {
        this.$message.error("请输入电话");
      } else {
        feedback(qs.stringify(this.form)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.form = {
                name: "",
                phone: "",
                email: "",
                address: "",
                content: "",
              };
            }, 1000);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    about() {
      about(qs.stringify(this.form)).then((res) => {
        if (res.data.code == 1) {
          this.dataInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.address = JSON.parse(localStorage.getItem("address"));
    this.about();
    this.backTop();
  },
};
</script>

<style scoped>
.about {
  padding: 50px;
  background: #fff;
  overflow: hidden;
}
.about_module {
  width: 1200px;
  margin: 0 auto;
}
.about_en {
  font-size: 46px;
  font-weight: bold;
  color: #004e9f;
}
.about_title {
  font-size: 24px;
  color: #004e9f;
}
.about_text {
  font-size: 14px;
  color: #999;
}
.about_content {
  font-size: 14px;
  color: #333;
  margin-top: 50px;
}

.contact {
  padding: 80px 0;
  background: #f6f6f6;
}
.contact_title {
  font-size: 26px;
  color: #333;
  text-align: center;
  font-weight: bold;
}
.contact_module {
  width: 900px;
  margin: 50px auto 0 auto;
}
.contact_item {
  position: relative;
  width: 430px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 25px;
  font-size: 14px;
  color: #333;
  margin-right: 40px;
  margin-bottom: 25px;
}
.contact_important {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 14px;
  width: 14px;
  left: 15px;
  color: #de0e0e;
}
.contact_input {
  border: none;
  background: none;
  font-size: 14px;
  margin-left: 10px;
}
.contact_item:nth-child(2n) {
  margin-right: 0;
}

.contact_content {
  position: relative;
  width: 900px;
  height: 104px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 15px 25px;
  font-size: 14px;
  color: #333;
  margin-right: 40px;
  margin-bottom: 25px;
}
.contact_area {
  border: none;
  background: none;
  font-size: 14px;
  margin-left: 10px;
}
.contact_submit {
  width: 120px;
  height: 40px;
  background: #004e9f;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  margin: 50px auto 0 auto;
}
.map {
  padding: 0 0 50px 0;
}
.map_image {
  width: 1200px;
  display: block;
  margin: 0 auto;
}
.map_title {
  padding: 15px;
  width: 1200px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  margin: 0 auto;
  background: #f6f6f6;
}
.map_icon {
  display: block;
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.mapP {
  padding: 0 0 30px 0;
}
.mapP_title {
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  margin: 0 auto;
  background: #f6f6f6;
}
.contactP {
  padding: 20px 20px 0 20px;
}
.contactP_item {
  width: 100%;
  box-sizing: border-box;
}
</style>